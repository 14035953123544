import {
  AppState,
  Auth0Provider,
  useAuth0,
  withAuthenticationRequired,
} from "@auth0/auth0-react";
import React, { memo, useEffect } from "react";
import {
  Navigate,
  Outlet,
  RouteObject,
  createBrowserRouter,
  useLocation,
  useNavigate,
} from "react-router-dom";
import { MainLayout, MultiStepForm } from "../components";
import config, { ROUTES } from "../config";
import {
  CheckoutSuccess,
  Dashboard,
  Error,
  HelpCenter,
  HelpCenterArticle,
  Loading,
  Roadmap,
  Signup,
  Subscription,
} from "../pages";
import QMS from "../pages/QMS";
import { useStore } from "../stores";
import { authHelper } from "../stores/helpers";

type ProtectedRouteProps = {
  component: any;
  // path: string;

  [key: string]: any;
};

const ProtectedRoute = ({ component, ...args }: ProtectedRouteProps) => {
  const { getAccessTokenSilently, user, isAuthenticated } = useAuth0();
  const { userStore } = useStore();

  useEffect(() => {
    if (user && isAuthenticated) {
      authHelper.setAccessTokenSilently(getAccessTokenSilently);
      userStore.setAuth0User(user);
    }
  }, [user, isAuthenticated]);

  const Component = memo(
    withAuthenticationRequired(component, {
      ...args,
      // returnTo: args.path,
      onRedirecting: () => <Loading />,
    })
  );

  return <Component />;
};

const GENERAL_ROUTES: RouteObject[] = [
  {
    path: ROUTES.CHECKOUT_SUCCESS,
    element: <CheckoutSuccess />,
  },
  {
    index: true,
    path: "/",
    element: <Navigate to={ROUTES.DEVICE_MANAGEMENT} replace />,
  },
  {
    path: ROUTES.DEVICE_MANAGEMENT,
    element: <Dashboard />,
    // loader: DashboardLoader,
    index: true,
  },
  {
    path: ROUTES.DEVICE_OVERVIEW,
    element: <Roadmap />,
  },
  {
    path: ROUTES.HELP_CENTER,
    element: <HelpCenter />,
  },
  { path: ROUTES.HELP_CENTER_ARTICLE, element: <HelpCenterArticle /> },
  { path: ROUTES.QMS, element: <QMS /> },
  { path: ROUTES.QMS_OPEN_DOC, element: <QMS /> },
  { path: ROUTES.TEMPLATE_ASSISTANT, element: <MultiStepForm /> },
  {
    path: ROUTES.SUBSCRIPTION,
    element: <Subscription />,
  },
  {
    path: ROUTES.CHECKOUT_SUCCESS,
    element: <CheckoutSuccess />,
  },
];

type Auth0ProviderWithRedirectCallbackProps = {
  children: React.ReactNode;
};

const Auth0ProviderWithRedirectCallback = ({
  children,
  ...props
}: Auth0ProviderWithRedirectCallbackProps) => {
  const navigate = useNavigate();

  const onRedirectCallback = (appState?: AppState) => {
    // Check if there's a saved returnTo in sessionStorage
    const savedReturnTo = sessionStorage.getItem("returnTo");

    // Use the saved returnTo if it exists, otherwise use appState.returnTo or fall back to root
    const returnTo = savedReturnTo || (appState && appState.returnTo) || "/";

    // Clear the saved returnTo from sessionStorage
    sessionStorage.removeItem("returnTo");

    // Navigate to the appropriate location
    navigate(returnTo);
  };

  return (
    <Auth0Provider
      domain={config.auth.issuerBaseUrl}
      clientId={config.auth.clientId}
      audience={config.auth.audience}
      redirectUri={window.location.origin + "/callback"}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
      {...props}
    >
      {children}
    </Auth0Provider>
  );
};

const SaveLocationWrapper = ({ children }: { children: React.ReactNode }) => {
  const location = useLocation();
  const { isAuthenticated } = useAuth0();

  useEffect(() => {
    if (!isAuthenticated) {
      const currentPath = `${location.pathname}${location.search}`;
      const isAuthCallback = location.pathname.startsWith("/callback");

      if (!isAuthCallback) {
        sessionStorage.setItem("returnTo", currentPath);
      }
    }
  }, [location, isAuthenticated]);

  return <>{children}</>;
};

export const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <Auth0ProviderWithRedirectCallback>
        <Outlet />
      </Auth0ProviderWithRedirectCallback>
    ),
    errorElement: <Error />,
    children: [
      {
        path: ROUTES.CALLBACK,
        element: <Loading />,
      },
      {
        path: ROUTES.SIGNUP,
        element: <Signup />,
      },
      {
        path: "/",
        element: (
          <SaveLocationWrapper>
            <MainLayout />
          </SaveLocationWrapper>
        ),
        children: [
          {
            path: "/",
            element: <ProtectedRoute component={Outlet} />,
            children: GENERAL_ROUTES.map((route) => ({
              path: route.path,
              element: route.element,
              loader: route.loader || undefined,
            })),
          },
        ],
      },
    ],
  },
]);
